<template>
  <base-dialog content-class="overflow-x-hidden" fullscreen hide-overlay v-model="visible" persistent>
    <component
      :is="viewer"
      :editable="editable"
      :event="current"
      @pollEvent="pollEvent"
      :hasPrevious="index > 0"
      :hasNext="index < events.length - 1"
      @previous="indexChanged(index - 1)"
      @next="indexChanged(index + 1)"
      @deleteEvent="() => { deleteEventConfirmDialogVisible = true }"
      @updateEvent="updateEvent"
      :sensor="sensor"
      :title="sensor.name"
      @close="close"
      v-if="visible"
    >
      <template #thumbnails>
        <thumbnail-slider
          :items="events"
          keyName="id"
          :value="index"
          @scrolledToEnd="tryLoadNextEvents"
        >
          <template #item="props">
            <event-thumbnail
              :event="props.item"
              :class="{ active: index == props.index, 'selectable-thumbnail': true }"
              @click="index = props.index"
            />
          </template>
        </thumbnail-slider>
      </template>
    </component>
    <confirm-dialog
      v-model="deleteEventConfirmDialogVisible"
      :message="$t('msg.delete_event_confirm')"
      @yes="deleteEvent"
    />
  </base-dialog>
</template>

<i18n lang="yaml">
ja:
  msg:
    delete_event_confirm: このイベントを削除します、よろしいですか？
    deleted: イベントを削除しました。
    not_found: |
      指定されたセンサーが存在しません。
      識別子が間違っている、または共有が解除された可能性があります。

en:
  msg:
    delete_event_confirm: This event will be deleted. Are you sure?
    deleted: Event was deleted.
    not_found: |
      The specified sensor does not exist.
      Either the wrong id was specified or your permissions on this sensor have been revoked.
</i18n>

<script>
import SensorTypes from '@/mixins/sensorTypes';
import EventContainer from '@/mixins/eventContainer';
import Notifications from '@/mixins/notifications';

import BaseDialog from '@/components/atoms/BaseDialog';
import ConfirmDialog from '@/components/atoms/ConfirmDialog';
import EventThumbnail from '@/components/organisms/EventThumbnail';
import ThumbnailSlider from '@/components/ThumbnailSlider';

import RatView from './RatView';
import InsectV2View from './InsectV2View';
import HykecamView from './HykecamView';
import FlyCountView from './FlyCountView';
import FlyScanView from './FlyScanView';
import FlyView from './FlyView';

export default {
  name: 'GalleryView',
  mixins: [
    EventContainer,
    Notifications,
    SensorTypes,
  ],
  props: {
    requestParams: {  // For EventContainer mixin
      type: Object,
      required: true,
    },
    displayData: {
      type: Object,
      default() {
        return {
          events: [],
          index: null,
          sensor: null,
        };
      },
    },
    visible: Boolean,
  },
  components: {
    BaseDialog,
    ConfirmDialog,
    EventThumbnail,
    FlyCountView,
    FlyScanView,
    FlyView,
    HykecamView,
    InsectV2View,
    RatView,
    ThumbnailSlider,
  },
  computed: {
    current() {
      return this.events[this.index];
    },
    editable() {
      return this.sensor.permissions.includes('full');
    },
    sensor() {
      return this.displayData.sensor || {};
    },
    sensorType() {
      return this.sensor.type;
    },
    sensorId() {  // For EventContainer mixin
      return this.sensor.id;
    },
    updatedEvent() {
      return this.$store.getters.getUpdatedEvent(this.sensorId);
    },
    viewer() {
      switch (this.sensorType) {
        case this.SENSOR_TYPES.INSECT_V2:
          return InsectV2View;
        case this.SENSOR_TYPES.RAT:
          return RatView;
        case this.SENSOR_TYPES.HYKECAM:
          return HykecamView;
        case this.SENSOR_TYPES.FLY_COUNT:
          return FlyCountView;
        case this.SENSOR_TYPES.FLY:
          return FlyView;
        case this.SENSOR_TYPES.FLY_SCAN:
          return FlyScanView;
        default:
          return FlyCountView;
      }
    },
  },
  data() {
    return {
      events: this.displayData.events,
      index: this.displayData.index,
      allEventsLoaded: false,
      deleteEventConfirmDialogVisible: false,
    };
  },
  methods: {
    close() {
      this.$emit('visibilityChanged', false);
    },
    deleteEvent: async function () {
      await this.$store.dispatch('deleteEvent', { event: this.current });

      this.events.splice(this.index, 1);

      if (this.events.length > 0) {
        // Update following event data because count_difference changed
        // Do this first before adjusting the index
        this.pollEvent(-1);

        // Prevent index to be out of array
        if (this.index === this.events.length) {
          this.index -= 1;
        }

        // Update GalleryView display
        this.indexChanged(this.index);
      } else {
        this.close();
      }

      // Update ActivityList events
      this.$store.dispatch('reloadSensor', this.sensorId);

      this.notifySuccess(this.$t('msg.deleted'));
    },
    indexChanged(value) {
      this.index = value;
      this.$emit('displayedEventChanged', this.events[value]);
    },
    pollEvent: async function (relativeIndex) {
      const index = this.index + (relativeIndex || 0);
      if (index < 0 || index >= this.events.length) {
        return;
      }

      this.$store.dispatch('pollUpdatingEvent', { event: this.events[index] });
    },
    tryLoadNextEvents: async function () {
      if (!this.allEventsLoaded) {
        try {
          await this.loadNextEvents(16);
        } catch (error) {
          this.notifyError('msg.not_found');
          this.close();
        }
      }
    },
    updateEvent: async function (attributes) {
      await this.$store.dispatch('updateSensorEvent', { event: this.current, attributes });
    },
  },
  model: {
    prop: 'visible',
    event: 'visibilityChanged',
  },
  watch: {
    displayData(value) {
      this.events = value.events;
      this.allEventsLoaded = false;
      this.eventContainerInternal.lastRetrieved = this.events[this.events.length - 1].timestamp;
      this.indexChanged(value.index);
    },
    updatedEvent(updated) {
      if (updated) {
        const index = this.events.findIndex(ev => ev.id === updated.id);
        if (index !== -1) {
          this.events.splice(index, 1, updated);
        }
      }
    },
  },
};
</script>

<style scoped lang="sass">
.v-card
  background-color: #f2f2f2 !important
  min-height: 100%

.selectable-thumbnail
  min-height: 200px
  max-height: 300px
  width: 300px
  opacity: 0.8
  border: 3px solid
  border-color: transparent

  &.active
    border-color: #0068B6
    opacity: 1.0

::v-deep .v-slide-group
  padding: 0 4px 8px

  &__prev
    margin-right: 4px

  &__next
    margin-left: 4px

  &__prev,
  &__next
    border-radius: 4px
    transition: background-color 0.3s
    background-color: #999

    &:hover
      background-color: #888

    &--disabled
      background-color: #ccc

  .v-icon
    color: white!important
</style>
