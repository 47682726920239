var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "gallery-view--hykecam" },
    [
      _c("gallery-view-toolbar", {
        attrs: {
          editable: _vm.editable,
          event: _vm.event,
          sensor: _vm.sensor,
          canDownload: _vm.canDownload,
          title: _vm.title,
        },
        on: {
          download: _vm.download,
          update: function (attr) {
            return _vm.$emit("updateEvent", attr)
          },
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _vm._v(" "),
      _c(
        "layout",
        {
          attrs: { hasPrevious: _vm.hasPrevious, hasNext: _vm.hasNext },
          on: {
            previous: function ($event) {
              return _vm.$emit("previous")
            },
            next: function ($event) {
              return _vm.$emit("next")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("tag-display", {
                            attrs: { editable: _vm.editable, event: _vm.event },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("gallery-view-fullscreen-enter", {
                            model: {
                              value: _vm.fullscreen,
                              callback: function ($$v) {
                                _vm.fullscreen = $$v
                              },
                              expression: "fullscreen",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "media mb-2" },
                [
                  _vm.resourceType === "video"
                    ? _c("event-video", {
                        attrs: {
                          src: _vm.event.attachments.video,
                          thumbnail: _vm.event.attachments.thumbnail,
                          fullscreen: _vm.fullscreen,
                        },
                        on: {
                          enterFullscreen: function ($event) {
                            _vm.fullscreen = true
                          },
                          exitFullscreen: function ($event) {
                            _vm.fullscreen = false
                          },
                        },
                      })
                    : _c(
                        "fullscreen",
                        {
                          model: {
                            value: _vm.fullscreen,
                            callback: function ($$v) {
                              _vm.fullscreen = $$v
                            },
                            expression: "fullscreen",
                          },
                        },
                        [
                          _c("loading-image", {
                            class: { fullscreen: _vm.fullscreen },
                            attrs: { src: _vm.imageUrl },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("thumbnails"),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }