<template>
  <div class="activity-list">
    <page-title-area>
      <h1>ACTIVITY</h1>
      <template #side-content>
        <v-btn text @click="expandSensors">
          <v-icon left color="primary">fa-chevron-circle-down</v-icon>
          {{ $t('button.expand_all') }}
        </v-btn>
      </template>
    </page-title-area>
    <query-filter
      :loading="loading"
      :clearable="hasEvents() || hasFilter"
      @apply="executeQuery"
      @clear="clearQuery"
    >
      <v-row dense class="mb-4">
        <v-col>
          <v-select
            class="query-filter__input--type"
            v-model="form.sensor.type"
            :items="sensorTypeItems"
            :placeholder="$t('field.type')"
            :disabled="hasSensorId"
            hide-details
            clearable
            outlined
            prepend-icon="location_searching"
          />
        </v-col>
        <v-col>
          <autocomplete-text-field
            allow-new-values
            clearable
            v-model="form.sensor.place"
            :label="$t('field.installation_site')"
            :items="placeItems"
            :disabled="hasSensorId"
            icon="location_on"
            data-testid="sensor_place_query"
          />
        </v-col>
        <v-col>
          <autocomplete-text-field
            allow-new-values
            clearable
            v-model="form.sensor.name"
            :label="$t('field.sensor_name')"
            :items="nameItems"
            icon="location_searching"
            :disabled="hasSensorId"
            data-testid="sensor_name_query"
          />
        </v-col>
        <v-col>
          <term-picker v-model="form.event.term">
            <template #activator="{ on, attrs }">
              <v-text-field
                :placeholder="$t('field.time_period')"
                hide-details
                outlined
                dense
                prepend-inner-icon="fa-calendar"
                readonly
                :value="termLabel"
                v-bind="attrs"
                v-on="on"
              />
            </template>
          </term-picker>
        </v-col>
        <v-col>
          <tag-picker v-model="form.event.tags">
            <template #activator="{ on, attrs }">
              <v-text-field
                :placeholder="$t('field.tag')"
                v-model="tagsLabel"
                prepend-inner-icon="local_offer"
                hide-details
                readonly
                outlined
                dense
                v-on="on"
                v-bind="attrs"
              />
            </template>
          </tag-picker>
        </v-col>
      </v-row>
      <div class="filter__checkbox text-md-center">
        <div class="checkbox-box">
          <v-checkbox v-model="form.event.pickup" hide-details />
            <div class="checkbox-box__label"><v-icon>star</v-icon><span>{{ $t('field.pickup') }}</span></div>
        </div>
        <div class="checkbox-box">
          <v-checkbox v-model="form.event.misdetection" hide-details />
          <div class="checkbox-box__label"><v-icon>sentiment_very_dissatisfied</v-icon>{{ $t('field.false_positives') }}</div>
        </div>
        <div class="checkbox-box">
          <v-checkbox v-model="form.event.exceptMisdetection" hide-details />
          <div class="checkbox-box__label">{{ $t('field.omit_false_positives') }}</div>
        </div>
        <div class="checkbox-box">
          <v-checkbox v-model="form.event.exceeded" hide-details />
          <div class="checkbox-box__label">{{ $t('field.exceeding_threshold') }}</div>
        </div>
        <div class="checkbox-box">
          <v-checkbox v-model="form.event.includeHidden" hide-details />
          <div class="checkbox-box__label"><v-icon>fa-eye-slash</v-icon>{{ $t('field.include_hidden') }}</div>
        </div>
        <div class="checkbox-box">
          <v-checkbox
            v-model="form.sensor.onlyBookmarked"
            hide-details
            data-testid="bookmarked_sensors_checkbox"
          />
          <div class="checkbox-box__label"><v-icon>mdi-bookmark</v-icon>{{ $t('field.bookmarked_sensors') }}</div>
        </div>
      </div>
    </query-filter>
    <div class="text-right pt-4">
      <insect-count-line-charts-button :sensors="visibleSensors" :request-params="requestParams"/>
    </div>
    <sensor-events
      v-for="sensor in sensors"
      :sensor="sensor"
      :requestParams="requestParams"
      :expanded="expandedSensors.includes(sensor.id)"
      @update:expanded="v => updateExpanded(sensor.id, v)"
      @event:selected="(selectedEvent, events) => showEvents(events, selectedEvent, sensor)"
      @becameVisible="() => setVisible(sensor.id)"
      ref="sensorEvents"
      :key="sensor.id"
    />
    <no-sensors-message v-if="!loading && sensors.length == 0"/>
    <gallery-view-legacy
      v-model="galleryVisible"
      :displayData="galleryData"
      :requestParams="requestParams"
    />
  </div>
</template>

<i18n lang="yaml">
ja:
  button:
    expand_all: すべてのセンサーを展開
    apply_filter: 読み込み
    clear_filter: クリア

  field:
    installation_site: 設置場所
    sensor_name: センサー名
    type: センサー種別
    tag: タグ
    not_viewed: 未閲覧
    pickup: ピックアップ
    false_positives: 誤検出
    omit_false_positives: 誤検出以外
    exceeding_threshold: しきい値を超えた画像
    include_hidden: 非表示も含む
    time_period: 期間
    bookmarked_sensors: よく見るセンサー

en:
  button:
    expand_all: Expand all
    apply_filter: Apply
    clear_filter: Clear

  field:
    installation_site: Installation site
    sensor_name: Sensor name
    type: Sensor type
    tag: Tag
    not_viewed: Not viewed
    pickup: Selected
    false_positives: False positives
    omit_false_positives: Omit false positives
    exceeding_threshold: Exceeding threshold
    include_hidden: Include hidden
    time_period: Time period
    bookmarked_sensors: Bookmarked sensors
</i18n>

<script>
import { datePeriodToTimestamps } from '@/libs/time';
import { storeToLocalStorage, restoreFromLocaleStorage } from '@/libs/localstorage';
import Features from '@/mixins/features';
import SensorTypes from '@/mixins/sensorTypes';

import AutocompleteTextField from '@/components/atoms/AutocompleteTextField';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import SelectBox from '@/components/atoms/SelectBox';
import QueryFilter from '@/components/molecules/QueryFilter';
import GalleryViewLegacy from '@/components/GalleryView/GalleryViewLegacy';
import TagPicker from '@/components/TagPicker';
import TermPicker from '@/components/TermPicker';
import { buildDefaultQuery, filterSensors, parseToValidQuery } from './queries';
import InsectCountLineChartsButton from './InsectCountLineChartsButton';
import NoSensorsMessage from './NoSensorsMessage';
import SensorEvents from './SensorEvents';

const matchIgnoreCase = (str, query) => str.toLowerCase().includes(query.toLowerCase());

export default {
  name: 'activity-legacy',
  mixins: [
    Features,
    SensorTypes,
  ],
  components: {
    AutocompleteTextField,
    GalleryViewLegacy,
    InsectCountLineChartsButton,
    NoSensorsMessage,
    PageTitleArea,
    QueryFilter,
    SelectBox,
    SensorEvents,
    TagPicker,
    TermPicker,
  },
  computed: {
    hasFilter() {
      return !this._.isEqual(this.query, buildDefaultQuery());
    },
    hasSensorId() {
      return !!this.query.sensor.id;
    },
    availables() {
      let sensors = this.$store.getters.getSensors;
      if (this.form.sensor.place) {
        sensors = sensors.filter(sensor => matchIgnoreCase(sensor.place, this.form.sensor.place));
      }
      if (this.form.sensor.name) {
        sensors = sensors.filter(sensor => matchIgnoreCase(sensor.name, this.form.sensor.name));
      }
      return sensors;
    },
    nameItems() {
      return this.availables.map(sensor => sensor.name).sorted();
    },
    placeItems() {
      return this.availables.map(sensor => sensor.place).sorted();
    },
    sensorTypeItems() {
      const sensors = this.$store.getters.getSensors;
      const types = this._.uniq(sensors.map(sensor => sensor.type));
      return this._.sortBy(types.map(type => ({
        index: this.SENSOR_TYPE_INDEXES[type],
        text: this.SENSOR_TYPE_NAMES[type],
        value: type,
      })), 'index');
    },
    sensors() {
      return filterSensors(
        this.$store.getters.getSensors.toArray(),
        this.query,
      );
    },
    tagsLabel() {
      return this.form.event.tags.join(', ');
    },
    termLabel() {
      const query = this.form.event;
      if (this._.isNull(query.term.from || query.term.to)) {
        return null;
      }
      return `${query.term.from || ''} - ${query.term.to || ''}`;
    },
    totalCount() {
      return null;
      // TODO: Rewrite after switching to RDB
      // const detectedCount = events => events.filter(ev => ev.detected).length;
      // return this._.reduce(this.eventsBySensor, (sum, evs) => sum + detectedCount(evs), 0);
    },
    visibleSensors() {
      return this.sensors.filter(sensor => this.visibleSensorIds.includes(sensor.id));
    },
  },
  created: async function () {
    await this.$store.dispatch('requestSensors');
    if (this.$route.query.sensorId) {
      const sensor = this.$store.getters.getSensor(this.$route.query.sensorId);
      this.form.sensor = {
        ...this.form.sensor,
        type: sensor.type,
        name: sensor.name,
        place: sensor.place,
      };
      this.updateQuery();
      this.storeQuery();
      await this.updateRequestParams();
    } else {
      this.restoreQuery();
    }
  },
  data() {
    return {
      form: buildDefaultQuery(),
      query: buildDefaultQuery(),
      expandedSensors: [],
      galleryData: {
        current: null,
        events: [],
        sensor: null,
      },
      galleryVisible: false,
      requestParams: {},
      loading: false,
      visibleSensorIds: [],
    };
  },
  methods: {
    clearEvents() {
      this.$refs.sensorEvents.forEach((s) => {
        s.clearEvents();
      });
      this.visibleSensorIds = [];
    },
    clearQuery() {
      this.form = buildDefaultQuery();
      this.query = buildDefaultQuery();
      this.$router.push({ query: {} });

      this.clearEvents();
    },
    executeQuery: async function () {
      // Ensure all query form blur events to update the form data have finished before updating
      // the query $nextTick is not enough since Vue will not give up control until this function
      // is finished
      setTimeout(() => {
        this.updateQuery();
        this.storeQuery();
        this.updateRequestParams();
      });
    },
    expandSensors() {
      this.expandedSensors = this.sensors.map(s => s.id);
    },
    getSensorEvents(sensorId, params) {
      return this.$store.dispatch('getEvents', {
        sensorId,
        params: {
          ...this.requestParams,
          ...params,
        },
      });
    },
    hasEvents() {
      const sensorEvents = this.$refs.sensorEvents || [];
      const events = this._.flatten(sensorEvents.map(sEvent => sEvent.events));
      return !this._.isEmpty(events);
    },
    isEditable(sensor) {
      return sensor.permissions.includes('full');
    },
    setVisible(sensorId) {
      this.visibleSensorIds = [...this.visibleSensorIds, sensorId];
    },
    showEvents(events, selected, sensor) {
      this.galleryData = {
        sensor: sensor,
        events: events,
        index: events.indexOf(selected),
      };
      this.galleryVisible = true;
    },
    storeQuery() {
      if (!this.featureIsActive('store_activity_query')) {
        return;
      }
      const currentUser = this.$store.getters.getCurrentUser;
      this.storeActivityQuery(currentUser.id, this.query);
    },
    restoreQuery() {
      if (!this.featureIsActive('store_activity_query')) {
        return;
      }
      const currentUser = this.$store.getters.getCurrentUser;
      this.query = parseToValidQuery(this.restoreActivityQuery(currentUser.id));
      this.form = { ...this.query };
    },
    suppressRejection: async (promise) => {
      try {
        await promise;
      } catch (error) {
        // Do nothing
      }
    },
    updateExpanded(sensorId, value) {
      if (value) {
        this.expandedSensors = [...this.expandedSensors, sensorId];
      } else {
        this.expandedSensors = this.expandedSensors.filter(id => id !== sensorId);
      }
    },
    updateRequestParams: async function () {
      this.loading = true;
      const eventQuery = this.query.event;

      const params = this._.pickBy({
        pickup: eventQuery.pickup ? '1' : null,
        exceeded: eventQuery.exceeded ? '1' : null,
        tags: eventQuery.tags.length > 0 ? JSON.stringify(eventQuery.tags) : null,
        with_hidden: eventQuery.includeHidden ? '1' : null,
        ...datePeriodToTimestamps(this.query.event.term),
      });
      if (eventQuery.misdetection) {
        params.misdetection = '1';
      } else if (eventQuery.exceptMisdetection) {
        params.misdetection = '0';
      }

      this.requestParams = params;
      this.expandedSensors = [];

      if (this.sensors.length > 0) {
        await this.$nextTick();
        this.clearEvents();

        await this.$nextTick();
        await Promise.all(this.$refs.sensorEvents.map(s =>
          this.suppressRejection(s.loadNextEvents(4)),
        ));
      }
      this.loading = false;
    },
    updateQuery() {
      const sensorId = this.$route.query.sensorId || this.query.sensor.id;
      this.query = {
        sensor: {
          ...this.form.sensor,
          id: sensorId || null,
        },
        event: { ...this.form.event },
      };
    },
  },
  provide() {
    return {
      getSensorEvents: this.getSensorEvents,
    };
  },
  inject: {
    storeActivityQuery: {
      default: () => (userId, query) => {
        storeToLocalStorage('activityQuery', {
          ...restoreFromLocaleStorage('activityQuery'),
          [userId]: query,
        });
      },
    },
    restoreActivityQuery: {
      default: () => (userId) => {
        const query = restoreFromLocaleStorage('activityQuery');
        return query[userId] || buildDefaultQuery();
      },
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
.activity-list
  .checkbox-box
    display: inline-flex
    align-items: center
    margin-right: 16px

    ::v-deep .v-input--checkbox
      margin: 0
      padding: 2px 0 0

    .checkbox-box__label
      display: flex
      align-items: center

  ::v-deep .v-text-field
    background-color: white

    .v-input__slot::before,
    .v-input__slot::after
      display: none

    .v-icon
      font-size: 20px
      padding-left: 4px

  ::v-deep .checkbox-box__label .v-icon
    font-size: 20px
    margin-right: 4px

  ::v-deep .term-picker,
  ::v-deep .tag-picker
    .menu
      width: 100%

  .query-filter
    .col
      width: 0
      flex-basis: 100%
      @media #{map-get($display-breakpoints, 'md-and-up')}
        flex-basis: 0%

    .v-input
      padding-top: 0
      padding-bottom: 0

      ::v-deep label
        color: rgba(0, 0, 0, 0.38)

    &__input--type
      display: flex
      align-items: center

      ::v-deep .v-input
        &__prepend-outer
          margin-top: 0
          margin-left: 12px

        &__control
          height: 40px
          min-height: auto
          margin-left: -45px

        &__append-inner
          margin: 0
          align-self: auto

        &__slot
          min-height: 0
          padding-left: 45px !important

      ::v-deep .v-select
        &__selections
          height: 40px
          padding: 0 !important
</style>
