<template>
  <v-app class="pest-vision v-application v-application--is-ltr">
    <app-bar :admin="admin" />
    <main class="pest-vision__main">
      <v-container fluid py-10 class="pest-vision__container">
        <router-view></router-view>
      </v-container>
    </main>
    <bottom-navigation :admin="admin" />
    <scroll-button/>
    <notification/>
    <pending-download/>
    <logged-in-as-other-user-warning />
  </v-app>
</template>

<script>
import { startDownload } from '@/libs/downloads';

import Features from '@/mixins/features';

import LoggedInAsOtherUserWarning from '@/components/atoms/LoggedInAsOtherUserWarning';

import AppBar from '@/components/AppBar';
import BottomNavigation from '@/components/BottomNavigation';
import Notification from '@/components/Notification';
import PendingDownload from '@/components/PendingDownload';
import ScrollButton from '@/components/ScrollButton';

export default {
  name: 'app',
  mixins: [
    Features,
  ],
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoggedInAsOtherUserWarning,
    PendingDownload,
    BottomNavigation,
    AppBar,
    ScrollButton,
    Notification,
  },
  provide() {
    return {
      // Defined via yarn config-build
      // eslint-disable-next-line no-undef
      appConfig: CONFIG,
      assumeUserIdentity: async (user) => {
        this.$store.dispatch('assumeIdentity', user);
        await this.$store.dispatch('getUserInfo');
      },
      deleteStationPlace: async (placeId) => {
        await this.$http.delete(`/api/s_type/places/${placeId}`);
      },
      downloadAttachment: async (eventId, attachment) => {
        const { data: { download_path: downloadPath } } = await this.$http.get(
          `/api/attachments/${eventId}/${attachment}/download_path`,
        );

        startDownload(downloadPath);
      },
      fetchEvent: async (eventId) => {
        const response = await this.$http.get(`/api/v2/events/${eventId}`);
        return response.data;
      },
      fetchEvents: async ({ installationSiteId, ...params }) => {
        const response = await this.$http.get(
          `/api/installation_sites/${installationSiteId}/events`,
          { params },
        );
        return response.data;
      },
      fetchInstallationSite: async (id) => {
        const response = await this.$http.get(`/api/installation_sites/${id}`);
        return response.data;
      },
      fetchInstallationSites: async () => {
        const response = await this.$http.get('/api/installation_sites');
        return response.data;
      },
      fetchMirakunDevices: async () => {
        try {
          const response = await this.$http.get('/api/mirakun_devices');
          return response.data;
        } catch (error) {
          if (error.response.status === 422 && error.response.data.error === 'invalid_user_id') {
            throw new Error('invalid_user_id', { cause: error });
          } else {
            throw error;
          }
        }
      },
      fetchStationPlace: async (id) => {
        const response = await this.$http.get(
          `/api/s_type/places/${id}`,
        );
        return response.data;
      },
      fetchStationPlaceEvents: async (placeId, params) => {
        const response = await this.$http.get(
          `/api/s_type/places/${placeId}/events`,
          { params },
        );
        return response.data;
      },
      fetchStationPlaceRecentEvents: async (placeId) => {
        const response = await this.$http.get(`/api/s_type/places/${placeId}/recent_events`);
        return response.data;
      },
      fetchStationPlaces: async () => {
        const response = await this.$http.get(
          '/api/s_type/places',
          { params: { with_hidden: '1' } },
        );
        return response.data;
      },
      importStationPlaceCsvBase64String: async (base64String) => {
        try {
          const response = await this.$http.post('/api/s_type/places.csv', base64String, {
            headers: {
              'Content-Type': 'text/csv',
            },
          });

          return response.data;
        } catch (error) {
          throw new Error(error.response.data.error, { cause: error });
        }
      },
      resetIdentity: async () => {
        this.$store.dispatch('resetIdentity');
        this.$store.dispatch('clearSensors');
        await this.$store.dispatch('getUserInfo');
      },
      updateEvent: async (eventId, attributes) => {
        const response = await this.$http.patch(`/api/v2/events/${eventId}`, attributes);
        return response.data;
      },
      updateStationPlace: async (placeId, attributes) => {
        const response = await this.$http.patch(`/api/s_type/places/${placeId}`, attributes);
        return response.data;
      },
      updateStationPlaceEvent: async (eventId, attributes) => {
        const response = await this.$http.patch(`/api/s_type/events/${eventId}`, attributes);
        return response.data;
      },
    };
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.pest-vision
  background-color: #f2f2f2!important

  &__main
    margin-top: -64px
    min-height: 100vh
    padding-bottom: 80px
    padding-top: 64px

  &__container
    min-height: 100vh

  @media (min-width: 1024px)
    &__main
      padding-bottom: 200px

    &__container
      padding-left: calc(#{$container-padding-x} * 5)
      padding-right: calc(#{$container-padding-x} * 5)
</style>
