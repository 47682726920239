<template>
  <v-toolbar class="gallery-view-toolbar" height="auto">
    <v-toolbar-title class="d-flex align-center">
      <content-header class="w-100" :title="title" titleClass="title my-1">
        <template #icon><sensor-icon :sensor="sensor" /></template>
        <template #subtitle>
          <div class="overflow-ellipsis title details">
            {{ timestamp.date }}<span class="ml-3" />{{ `${timestamp.time} ${event.count || ''}` }}
          </div>
        </template>
      </content-header>
    </v-toolbar-title>
    <v-divider class="hidden-md-and-up"></v-divider>
    <v-toolbar-items>
      <slot />
      <toolbar-button
        icon="fa-star"
        :value="event.pickup"
        @click.native="update({pickup: !event.pickup})"
        :disabled="!!event.updating"
        v-if="editable"
      >
        {{ $t('menu.pickup') }}
      </toolbar-button>
      <tag-picker class="d-flex align-center" enable-add v-model="event.tags" @submit='tags => update({tags})' v-if='editable'>
        <template #activator="{ on, attrs }">
          <toolbar-button icon="fa-tag" :disabled="!!event.updating" v-on="on" v-bind="attrs">
            {{ $t('menu.tag') }}
          </toolbar-button>
        </template>
      </tag-picker>
      <download-toolbar-button
        :download-options="downloadOptions"
        :disabled="!canDownload"
        @download="type => $emit('download', type)"
      />
      <toolbar-button icon="fa-close" @click.native="close">
        {{ $t('menu.close') }}
      </toolbar-button>
    </v-toolbar-items>
  </v-toolbar>
</template>

<i18n lang="yaml">
ja:
  menu:
    pickup: 'ピックアップ'
    tag: 'タグ'
    close: '閉じる'

en:
  menu:
    pickup: 'Select'
    tag: 'Tag'
    close: 'Close'
</i18n>

<script>
import moment from 'moment';
import ToolbarButton from '@/components/atoms/ToolbarButton';
import ContentHeader from '@/components/ContentHeader';
import SensorIcon from '@/components/SensorIcon';
import TagPicker from '@/components/TagPicker';
import DownloadToolbarButton from './DownloadToolbarButton';

export default {
  name: 'GalleryViewToolbar',
  props: {
    editable: Boolean,
    event: {
      type: Object,
      default() {
        return {
          count: null,
          misdetection: false,
          pickup: false,
          timestamp: null,
        };
      },
    },
    sensor: {
      type: Object,
      default() {
        return {
          name: '',
          permissions: [],
          type: '',
        };
      },
    },
    canDownload: Boolean,
    downloadOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    title: String,
  },
  components: {
    ContentHeader,
    DownloadToolbarButton,
    SensorIcon,
    TagPicker,
    ToolbarButton,
  },
  computed: {
    timestamp() {
      const { timestamp } = this.event;
      return {
        date: moment.unix(timestamp).format('YYYY.M.D'),
        time: moment.unix(timestamp).format('HH:mm'),
      };
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    update(attributes) {
      this.$emit('update', attributes);
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

$header-height: 56px
$header-padding-x: $spacer * 5
$header-padding-y: $spacer

.gallery-view-toolbar
  ::v-deep .v-toolbar
    &__content
      display: flex
      flex-direction: column
      height: auto


    &__items
      height: $header-height
      margin: 0 !important
      padding: $header-padding-y 0

  ::v-deep .content-header
    color: map-get($blue, 'darken-2')
    margin: 0

  .divider
    background-color: #ccc
    height: 2px
    margin: 0 !important
    width: 100vw

@media #{map-get($display-breakpoints, 'md-and-up')}
  .gallery-view-toolbar
    padding: calc(#{$header-padding-y} * 2) $header-padding-x

    ::v-deep .v-toolbar
      &__content
        flex-direction: row

      &__title
        height: $header-height
        flex-grow: 1
        padding: 0

    .details
      padding-left: 3rem
      padding-right: 3rem
</style>
