<template>
  <base-dialog content-class="overflow-x-hidden" fullscreen hide-overlay v-model="visible" persistent>
    <component
      :is="viewer"
      :editable="editable"
      :event="current"
      @pollEvent="pollEvent"
      :hasPrevious="index > 0"
      :hasNext="index < events.length - 1"
      @previous="$emit('change', index - 1)"
      @next="$emit('change', index + 1)"
      :sensor="sensor"
      :title="installationSite.name"
      @updateEvent="updateEvent"
      @close="$emit('close')"
      v-if="visible"
    >
      <template #thumbnails>
        <thumbnail-slider
          :items="events"
          keyName="id"
          :value="index"
          @scrolledToEnd="tryLoadNextEvents"
        >
          <template #item="props">
            <event-thumbnail
              :event="props.item"
              :class="{ active: index == props.index, 'selectable-thumbnail': true }"
              @click="$emit('change', props.index)"
            />
          </template>
        </thumbnail-slider>
      </template>
    </component>
  </base-dialog>
</template>

<i18n lang="yaml">
ja:
  msg:
    delete_event_confirm: このイベントを削除します、よろしいですか？
    deleted: イベントを削除しました。
    not_found: |
      指定されたセンサーが存在しません。
      識別子が間違っている、または共有が解除された可能性があります。

en:
  msg:
    delete_event_confirm: This event will be deleted. Are you sure?
    deleted: Event was deleted.
    not_found: |
      The specified sensor does not exist.
      Either the wrong id was specified or your permissions on this sensor have been revoked.
</i18n>

<script>
import SensorTypes from '@/mixins/sensorTypes';
import Notifications from '@/mixins/notifications';

import BaseDialog from '@/components/atoms/BaseDialog';
import EventThumbnail from '@/components/organisms/EventThumbnail';
import ThumbnailSlider from '@/components/ThumbnailSlider';

import RatView from './RatView';
import InsectV2View from './InsectV2View';
import HykecamView from './HykecamView';
import FlyCountView from './FlyCountView';
import FlyScanView from './FlyScanView';
import FlyView from './FlyView';

export default {
  name: 'GalleryView',
  props: {
    eventLoader: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    installationSite: {
      type: Object,
      required: true,
    },
    visible: Boolean,
  },
  model: {
    prop: 'index',
    event: 'change',
  },
  components: {
    BaseDialog,
    EventThumbnail,
    FlyCountView,
    FlyScanView,
    FlyView,
    HykecamView,
    InsectV2View,
    RatView,
    ThumbnailSlider,
  },
  mixins: [
    Notifications,
    SensorTypes,
  ],
  created() {
    this.fetchAttachedSensor();
  },
  computed: {
    current() {
      return this.events[this.index];
    },
    events() {
      return this.eventLoader.events;
    },
    editable() {
      return this.sensor.permissions.includes('full');
    },
    installationSiteType() {
      return this.installationSite.type;
    },
    sensor() {
      return this.$store.getters.getSensor(this.installationSite.sensor.id);
    },
    viewer() {
      switch (this.installationSiteType) {
        case this.SENSOR_TYPES.INSECT_V2:
          return InsectV2View;
        case this.SENSOR_TYPES.RAT:
          return RatView;
        case this.SENSOR_TYPES.HYKECAM:
          return HykecamView;
        case this.SENSOR_TYPES.FLY_COUNT:
          return FlyCountView;
        case this.SENSOR_TYPES.FLY:
          return FlyView;
        case this.SENSOR_TYPES.FLY_SCAN:
          return FlyScanView;
        default:
          return FlyCountView;
      }
    },
  },
  methods: {
    deleteEvent: async function () {
      // eslint-disable-next-line no-console
      console.log('deleteEvent');
    },
    fetchAttachedSensor: async function () {
      await this.$store.dispatch('requestSensor', this.installationSite.sensor.id);
    },
    pollEvent() {
      this.current.updating = true;
      this.eventLoader.pollEvent(this.current.id);
    },
    tryLoadNextEvents: async function () {
      if (!this.eventLoader.finished && !this.eventLoader.loading) {
        await this.eventLoader.loadNext(16);
      }
    },
    updateEvent: async function (attributes) {
      this.eventLoader.updateEvent(this.current.id, attributes);
    },
  },
};
</script>

<style scoped lang="sass">
.v-card
  background-color: #f2f2f2 !important
  min-height: 100%

.selectable-thumbnail
  min-height: 200px
  max-height: 300px
  width: 300px
  opacity: 0.8
  border: 3px solid
  border-color: transparent

  &.active
    border-color: #0068B6
    opacity: 1.0

::v-deep .v-slide-group
  padding: 0 4px 8px

  &__prev
    margin-right: 4px

  &__next
    margin-left: 4px

  &__prev,
  &__next
    border-radius: 4px
    transition: background-color 0.3s
    background-color: #999

    &:hover
      background-color: #888

    &--disabled
      background-color: #ccc

  .v-icon
    color: white!important
</style>
