<template>
  <activity-legacy v-if="!featureIsActive('sensor_installation_sites')" />
  <div v-else>
    <page-title-area>
      <h1>ACTIVITY</h1>
      <template #side-content>
        <v-btn text @click="expandAllSites">
          <v-icon left color="primary">fa-chevron-circle-down</v-icon>
          {{ $t('expand_all_button') }}
        </v-btn>
      </template>
    </page-title-area>
    <query-filter
      class="mb-4"
      :loading="loading"
      :clearable="displaysAnyEvents"
      @apply="executeQuery"
      @clear="clearQuery"
    >
    </query-filter>
    <template v-for="({ installationSite, eventLoader }, index) in eventLoadersWithData">
      <div :key="installationSite.id">
        <installation-site-events
          :installation-site="installationSite"
          :eventLoader="eventLoader"
          :expanded="expandedSiteIds.includes(installationSite.id)"
          @update:expanded="value => updateSiteExpandedState(installationSite.id, value)"
        />
        <v-divider class="mb-4 mt-8" v-if="index < eventLoadersWithData.length - 1"/>
      </div>
    </template>
  </div>
</template>

<i18n lang="yaml">
ja:
  expand_all_button: すべての設置場所を展開

en:
  expand_all_button: Expand All
</i18n>

<script>
import Features from '@/mixins/features';

import PageTitleArea from '@/components/atoms/PageTitleArea';
import QueryFilter from '@/components/molecules/QueryFilter';

import ActivityLegacy from './ActivityLegacy';
import InstallationSiteEvents from './InstallationSiteEvents';
import { buildEventLoader } from './eventLoader';

export default {
  name: 'activity',
  mixins: [
    Features,
  ],
  components: {
    ActivityLegacy,
    InstallationSiteEvents,
    PageTitleArea,
    QueryFilter,
  },
  data() {
    return {
      eventLoaders: [],
      installationSiteLoader: { installationSites: [], promise: null },
      loading: false,
      expandedSiteIds: [],
    };
  },
  computed: {
    displaysAnyEvents() {
      return this.eventLoadersWithData.length > 0;
    },
    eventLoadersWithData() {
      return this.eventLoaders.filter(({ eventLoader }) => eventLoader.events.length > 0);
    },
  },
  inject: [
    'fetchInstallationSites',
    'fetchEvents',
    'fetchEvent',
    'updateEvent',
  ],
  methods: {
    clearEvents() {
      this.eventLoaders = [];
      this.expandedSiteIds = [];
    },
    clearQuery() {
      this.clearEvents();
      this.loading = false;
    },
    executeQuery: async function () {
      this.loading = true;

      this.clearEvents();
      await this.waitUntilInstallationSitesAreLoaded();
      await this.loadInitialEvents();

      this.loading = false;
    },
    expandAllSites() {
      this.expandedSiteIds = this.eventLoaders.map(({ installationSite }) => installationSite.id);
    },
    loadInitialEvents: async function () {
      const { installationSites } = this.installationSiteLoader;
      this.eventLoaders = installationSites.map(installationSite => ({
        installationSite,
        eventLoader: buildEventLoader({
          fetchEvent: this.fetchEvent,
          fetchEvents: this.fetchEvents,
          updateEvent: this.updateEvent,
          params: { installationSiteId: installationSite.id },
        }),
      }));
      const loadPromises = this.eventLoaders.map(({ eventLoader }) =>
        eventLoader.loadNext(4),
      );
      await Promise.allSettled(loadPromises);
    },
    updateSiteExpandedState(siteId, value) {
      if (value) {
        this.expandedSiteIds = [...this.expandedSiteIds, siteId];
      } else {
        this.expandedSiteIds = this.expandedSiteIds.filter(id => id !== siteId);
      }
    },
    waitUntilInstallationSitesAreLoaded: async function () {
      await this.installationSiteLoader.promise;
    },
  },
  created() {
    if (this.featureIsActive('sensor_installation_sites')) {
      const loadSites = async () => {
        this.installationSiteLoader.installationSites = await this.fetchInstallationSites();
      };
      this.installationSiteLoader.promise = loadSites();
    }
  },
};
</script>

<style scoped lang="sass">
</style>
